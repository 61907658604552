<template>
  <div class="tag-details py-8">
    <TagAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <div class="ma-4">
          <div class="mb-6">
            <v-row>
              <v-col cols="6" md="4">
                <ViewingTitle :title="displayName" />
              </v-col>
              <v-col cols="6" md="2">
                <DeleteOrRestore
                  v-if="hasDeletePermissions"
                  :model="tag"
                  name="Tag"
                  redirect="/maintenance/tags"
                />
              </v-col>
            </v-row>

            <TagForm class="tag-details__form" :tag="tag" />
          </div>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Tag Request
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import TagAppbar from '@/views/Home/Maintenance/Tags/components/TagAppbar'
import TagForm from '@/views/Home/Maintenance/Tags/components/TagForm'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ViewingTitle from '@/components/common/ViewingTitle.vue'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore/index.vue'

export default {
  name: 'TagDetails',

  components: {
    DeleteOrRestore,
    ViewingTitle,
    TagAppbar,
    TagForm,
    Loading,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      tag: (state) => state.tag.tagDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.tag ? this.tag.label : null
    },

    hasDeletePermissions() {
      return validatePermissions([PERMISSION.TAGS_DELETE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getTagDetails: 'tag/getTagDetails',
    }),

    ...mapMutations({
      clearTagDetails: 'tag/clearTagDetails',
    }),

    async getTag() {
      this.loading = true
      await this.getTagDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getTag()
  },

  destroyed() {
    this.clearTagDetails()
  },

  watch: {
    $route() {
      this.getTag()
    },
  },
}
</script>

<style lang="scss" scoped>
.tag-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
