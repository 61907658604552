<template>
  <div class="tag-form">
    <v-form ref="form" class="tag-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Tag</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Tag"
            v-model="form.label"
            :error-messages="form.$getError('label')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building(s)</label>
          <SelectMultipleBuildings
            flat
            solo
            required
            class="mt-2"
            :label="'Select buildings'"
            :placeholder="'Select buildings'"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :deletableChips="true"
            :isHasSelectAll="true"
          />

          <v-checkbox
            v-model="form.is_active"
            hide-details="auto"
            label="Set as active"
            class="mt-0"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="mt-4">
            <v-btn
              v-if="hasPermissions"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Perk Form
 * ==================================================================================
 **/
import { mapActions, mapState } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { mdiPlus } from '@mdi/js'

export default {
  components: {
    SelectMultipleBuildings,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    tag: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: [],
        label: '',
        is_active: false,
      }),
      selected: [],
      options: {},
      loading: false,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      createdTag: (state) => state.tag.tagDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'Tag Information' : 'Create Tag'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateTag : this.createTag
    },

    hasPermissions() {
      return validatePermissions([PERMISSION.TAGS_CREATE], this.permissions)
    },

    isUpdate() {
      return !!this.tag
    },
  },

  watch: {
    tag() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createTag: 'tag/createTag',
      updateTag: 'tag/updateTag',
    }),

    initForm() {
      if (this.tag) {
        this.form.building_id = [...this.tag.buildings].map((item) => item.id)
        this.form.label = this.tag.label
        this.form.is_active = this.tag.is_active
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Tag details successfully updated!')
          } else {
            this.showSnackbar('Tag successfully created!')
            this.$router.push({
              name: 'maintenance.tags',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()

      if (this.isUpdate) {
        form.id = this.tag.id
      }

      return form
    },
  },
}
</script>
<style lang="scss">
.tag-form {
  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
  .hint {
    font-size: 12px; /* Adjust the font size as needed */
    color: #888; /* Choose a color for the hint text */
  }
}
</style>
