<template>
  <div class="create-perk">
    <TagAppbar />
    <div class="ma-4">
      <TagForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Tag
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import TagAppbar from '@/views/Home/Maintenance/Tags/components/TagAppbar'
import TagForm from '@/views/Home/Maintenance/Tags/components/TagForm'

export default {
  components: {
    TagAppbar,
    TagForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'tag/clearTagDetails',
    }),
  },
}
</script>
