<template>
  <Navbar name="tags" redirect="maintenance.tags" />
</template>
<script>
/**
 * ==================================================================================
 * Perk Appbar
 * ==================================================================================
 **/

import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
